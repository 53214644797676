<template>
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <h4 class="page-header">
          <b-icon icon="earth"></b-icon>
          <span v-if="!form.id">{{ $ml.get('proxies_new_proxy') }}</span>
          <span v-if="form.id">{{ $ml.get('proxies_proxy') }} {{form.mark}}</span>
        </h4>
      </div>
    </div>
    <form @submit.prevent="submit" novalidate>
      <b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>
      <div class="columns">
        <div class="column is-4">
          <b-field
              :label="$ml.get('proxies_mark') + ' *'"
              :message="'mark' in errors ? errors['mark'] : ''"
              :type="'mark' in errors ? 'is-danger' : ''" class="plate">
            <b-input v-model="form.mark" icon="tag" :placeholder="$ml.get('proxies_mark_example')"></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field
              :label="$ml.get('proxies_proto') + ' *'"
              :message="'proto' in errors ? errors['proto'] : ''"
              :type="'proto' in errors ? 'is-danger' : ''" class="plate">
            <b-select v-model="form.proto" icon="server-security" expanded>
              <option v-for="option in ['HTTP', 'SOCKS', 'SSH']" :value="option" :key="option">
                {{ option }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field
              :label="$ml.get('proxies_host') + ' *'"
              :message="'host' in errors ? errors['host'] : ''"
              :type="'host' in errors ? 'is-danger' : ''" class="plate">
            <b-input v-model="form.host" icon="server-security"></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field
              :label="$ml.get('proxies_port') + ' *'"
              :message="'port' in errors ? errors['port'] : ''"
              :type="'port' in errors ? 'is-danger' : ''" class="plate">
            <b-input v-model="form.port" icon="cog"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-2">
          <b-field
              :label="$ml.get('proxies_country') + ' *'"
              :message="'country' in errors ? errors['country'] : ''"
              :type="'country' in errors ? 'is-danger' : ''" class="plate">
            <b-select v-model="form.country" icon="earth" expanded>
              <option v-for="(option, property) in proxy_countries" :value="property" :key="property">
                {{ option }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field
              :label="$ml.get('proxies_login')"
              :message="'username' in errors ? errors['username'] : ''"
              :type="'username' in errors ? 'is-danger' : ''" class="plate">
            <b-input v-model="form.username" icon="cog"></b-input>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field
              :label="$ml.get('proxies_password')"
              :message="'password' in errors ? errors['password'] : ''"
              :type="'password' in errors ? 'is-danger' : ''" class="plate">
            <b-input v-model="form.password" icon="cog"></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field>
            <b-switch style="padding-top: 35px" v-model="form.is_mobile">
              {{ $ml.get('proxies_is_mobile') }}
            </b-switch>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <b-button :disabled="!isValid" type="is-info" native-type="submit">
            {{ $ml.get('common_save') }}
          </b-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from "vue";
import router from "@/router";

export default {
  name: "Create",
  data() {
    return {
      form: {
        id: null,
        mark: '',
        proto: '',
        host: '',
        port: '',
        username: '',
        password: '',
        country: 'ru',
        is_mobile: false,
      },
      loading: false,
      errors: [],
    }
  },
  computed: {
    isValid() {
      return this.form.host.length > 0 && this.form.proto.length > 0 && this.form.port && this.form.mark.length > 0 && this.form.country;
    },
    proxy_countries() {
      return this.$store.getters['data/proxy_countries']
    }
  },
  methods: {
    submit: function () {
      this.loading = true
      this.errors = []
      Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + 'proxy/index', {proxy: this.form}).then(resp => {
        this.flashMessage.success({
          title: this.$ml.get('common_request_success'),
          message: this.$ml.get('common_request_success_data_message'),
        })
        router.push('/proxies')
      }).catch(err => {
        if (err.response.status === 400) {
          this.errors = err.response.data.result
        } else {
          this.flashMessage.error({
            title: this.$ml.get('common_request_failed'),
            message: this.$ml.get('common_request_failed_message'),
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
  },
  mounted() {
    let id = this.$route.query.id
    if (id) {
      this.loading = true
      Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `proxy/index?id=${id}`).then((resp) => {
        this.form = resp.data.result
        if (resp.data.result.is_mobile === 1) {
          this.form.is_mobile = true
        } else {
          this.form.is_mobile = false
        }
      }).catch(err => {
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      }).finally(() => {
        this.loading = false
      })
    }
  },
  metaInfo() {
    return {
      title: this.$ml.get('titles_proxies_index')
    }
  }
}
</script>

<style scoped>
</style>